<template>
  <div class="flex justify-center">
    <div class="flex justify-between mt-48" v-if="company !== null">
      <div class="mt-20">
        <img
          :src="company.logo_url"
          alt="company-avatar"
          class="object-fit rounded-full w-56 h-56"
        />
      </div>
      <div class="pl-12">
        <h2 class="josefin text-3xl font-bold text-black pb-12">Company Profile</h2>
        <p class="lato text-xl font-bold text-black pb-2">{{ company.name }}</p>
        <span class="text-maingrey lato text-lg">{{ company.hq_address }}</span>
        <p class="company_description lato pt-6 pb-2">{{ company.description}}</p>
        <a :href="company.website" class="lato" style="color: #48BFE3; text-decoration: underline;">{{company.website}}</a>
        <!-- <div>
          <button @click="updateDetails" class="bg-primary mt-6 px-6 py-3 rounded-full text-white font-bold">Update details</button>
        </div> -->
      </div>
    </div>
    <div class="flex justify-center items-center flex-col mt-48" v-else>
      <h2 class="josefin text-xl md:text-3xl font-bold text-black pb-12">Company Profile</h2>
      <button
        @click="updateDetails"
        class="bg-primary text-white py-2 px-6 rounded-full"
      >Add Company Details</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { trimUserId } from '@/helpers/functions'

export default {
  data() {
    return {
      company: {}
    }
  },
  async created() {
    const payload = {
      user_id: trimUserId(this.$auth.user.sub)
    }
    await this.fetchCompanyProfile(payload)
    .then((res) => {
      this.company = res.data
    })
  },
  methods: {
    ...mapActions(['fetchCompanyProfile']),
    updateDetails() {
      this.$router.push('update-company-profile')
    }
  }

}
</script>

<style>
.company_description {
  width: 462px;
}

</style>